import React from "react"
import {TransitionGroup,Transition as ReactTransition} from "react-transition-group"

const timeout = 300
const getTransitionStyles = {
  entering: {
    position: `absolute`,
    transform: `scale(1.1)`,
    opacity: 0,
  },
  entered: {
    transition: `all ${timeout}ms ease-in-out`,
    transform: `scale(1)`,
    opacity: 1,
  },
  exiting: {
    transition: `all ${timeout}ms ease-in-out`,
    transform: `scale(0.9)`,
    opacity: 0,
  },
}

class Transition extends React.PureComponent {
  render() {
    const { children, location } = this.props
    return (
      <TransitionGroup>
        <ReactTransition key={location.pathname} timeout={{enter: timeout, exit: timeout}}>
          {status => (
            <div class="childrencontent" style={{...getTransitionStyles[status]}}>
              {children}
            </div> )}
        </ReactTransition>
      </TransitionGroup>
    )
  }
}

export default Transition