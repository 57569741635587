import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Paperplaneicon from '../utils/paperplaneicon.js' 
import logo from "../images/logo.png"


const Footer = ({ siteTitle }) => (
  <footer>
    <div class='footer'>
      <div class='footer-title'>
          {siteTitle}
      </div>
    <Link to='/'><img alt="logo" src={logo} id='logo'/></Link>
      <a href="mailto:contact@ydil.paris" class="contact-button">
          Contact 
          <Paperplaneicon id="icon-paperplane"></Paperplaneicon>
          <span>contact@ydil.paris</span>
      </a>
      <Link className='mentions' to='/mentions/' >Mentions Légales</Link>
    </div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
