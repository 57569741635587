import React from "react"
import PropTypes from "prop-types"
import Menu from "../layouts/menu"
import Footer from "../layouts/footer"
import Transition from '../components/transitions'

// import analytics from "../analytics"

const Layout = ({ children, location, isImpression}) => {
  let footer;
  let menu;
  let space;
  if (isImpression) {
    footer = <Footer siteTitle="Ydil - Conception et Impression 3D." ></Footer>

  }
  else
  {
    footer = <Footer siteTitle="Ydil - L'informatique au service de vos projets"></Footer>
    menu = <Menu></Menu>
    space = <div class="space"><div class="ship"><div class="ship-rotate"><div class="pod"></div><div class="fuselage"></div></div></div><div class="ship-shadow"></div><div class="mars"><div class="tentacle"></div><div class="flag"><div class="small-tentacle"></div></div><div class="planet"><div class="surface"></div><div class="crater1"></div><div class="crater2"></div><div class="crater3"></div></div></div><div class="test"></div></div>
  }
  return (
    <>
      
      {space}
      
      <div id='stars'></div>
      <div id='stars2'></div>
      <div id='stars3'></div>
      <div id='horizon'>
        <div class='glow'></div>
      </div>
      <div id='earth'></div>
      <div></div>
      {menu}

      

      <Transition  location = {location}>
        {children}
      </Transition>

      {footer}

      
      
      

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ({ children, location, pageContext }) => {
        return <Layout location={location} isImpression={pageContext.layout === "impression3d" ? true : false}>{children}</Layout>
}