import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"


const Menu = ({ siteTitle }) => (
  <menu>
    <nav class="menu" >
        <ul>
        <li class="icon-accueil" ><Link className='linkmenu' to='/' activeClassName="menu-active"><span>Qui sommes nous</span></Link></li>
        <li class="icon-fideliser"><Link className='linkmenu' to='/definit' activeClassName="menu-active"><span>Ce qui nous définit</span></Link></li>
        <li class="icon-passions"><Link className='linkmenu' to='/passion' activeClassName="menu-active"><span>Nos passions</span></Link></li>
        <li class="icon-contact"><Link className='linkmenu' to='/projet' activeClassName="menu-active"><span>Nous contacter</span></Link></li>
        <li class="icon-3d"><Link className='linkmenu' to='/impression3d' activeClassName="menu-active"><span>Impression 3D</span></Link></li>
    </ul>
    </nav>

  </menu>
)

Menu.propTypes = {
  siteTitle: PropTypes.string,
}

Menu.defaultProps = {
  siteTitle: ``,
}

export default Menu
